import React from "react";
import classes from "./auth.module.css";
import logo from "../../Assets/Images/metaMainLogo.png";
import Avatar from "../../Assets/Images/Avatar.png";
import Avatar1 from "../../Assets/Images/Avatar1.png";
import Avatar2 from "../../Assets/Images/Avatar2.png";
import leftarrow from "../../Assets/Images/leftarrow.png";
import rightarrow from "../../Assets/Images/rightarrow.png";
import Img1 from "../../Assets/Images/img-6.png";
import Img2 from "../../Assets/Images/img-2.png";
import Img3 from "../../Assets/Images/img-8.png";
import Img4 from "../../Assets/Images/img-4.png";
import Img5 from "../../Assets/Images/img-5.png";
import Img6 from "../../Assets/Images/img-7.png";
import Friend from "../../Assets/Images/selected-friend.png";
import CommonBg from "../../Assets/Images/common-bg.png";
// import rightarrow from "../../Assets/Images/rightarrow.png";
import { Typography, Box, Button } from "@mui/material";
import Slider from "react-slick";
import "./style.css";
import { Leftarrow, Rightarrow } from "../../Assets/Icons/icons";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const friendStyle = {
  position: "absolute",
  top: "86px",
  left: "32%",
};

function SelectedFriend() {
  const location = useLocation();
  const navigate = useNavigate();
  const my_friend = location?.state?.selectedItem;
  const { loggedUser } = useSelector((state) => state.userLogin);
  console.log(my_friend, "31");
  return (
    <div className={classes.chooseavatar}>
      <div className="container pt-3">
        <img
          src={logo}
          alt="Queensaward-logo"
          className={classes.metaverselogo}
        />

        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            margin: "50px auto",
            width: "48%",
            minHeight: "309px",
            position: "relative",
          }}
        >
          <img
            src={CommonBg}
            //  src={`${process.env.REACT_APP_API_URL}/${my_friend?.image}`}
            alt="selected-friend"
            width={"100%"}
            height={"100%"}
          />
          <img
            src={`https://api.queensaward.education/api/${my_friend?.image}`}
            alt="selected-friend"
            width={"40%"}
            style={friendStyle}
          />
        </Box>
        <Box mt={6}>
          <Typography variant="h2" className={classes.authHeader}>
            Hi {loggedUser?.student?.first_name}{" "}
            {loggedUser?.student?.last_name}, I am {my_friend?.name}. I will
            help you win gifts.
          </Typography>
        </Box>
        <div
          className={`mt-3 ${classes.loginSubmitBtnContainer}`}
          style={{ float: "right" }}
        >
          <Link to={"/home"}>
            <Button
              variant="contained"
              className={`${classes.loginSubmitBtn}`}
              type="submit"
              sx={{ marginTop: "10px", zIndex: "1" }}
            >
              Let's Play
            </Button>
          </Link>
        </div>
      </div>
      <img src={Img1} className={classes.imgone} />
      <img src={Img2} className={classes.imgtwo} />
      <img src={Img3} className={classes.imgthree} />
      <img src={Img4} className={classes.imgseven} />
      <img src={Img5} className={classes.imgfive} />
      <img src={Img5} className={classes.imgfive} />
      <img src={Img6} className={classes.imgsix} />
      <Typography
        className={classes.changeavatar}
        onClick={() => navigate("/choosefriend")}
      >
        Change Friend
      </Typography>
    </div>
  );
}

export default SelectedFriend;
