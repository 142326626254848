import React, { useEffect, useState } from "react";
import classes from "./payment.module.css";
import { Col, Row } from "react-bootstrap";
import {
  Typography,
  Card,
  Grow,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import logo from "../../Assets/Images/metaMainLogo.png";
import { useCreditCardValidator, images } from "react-creditcard-validator";
import {
  BaloonInHand,
  CloseBtnIcon,
  ColouredStarIcon,
  MoreHorizontal,
  RoundQuestionMark,
} from "../../Assets/Icons/icons";
import card from "../../Assets/Images/Card.png";
import paypal from "../../Assets/Images/Paypal.png";
import applePay from "../../Assets/Images/applePay.png";
import PaymentSuccessModel from "./paymentSuccessModel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetails,
  paymentSuccessApi,
} from "../../Redux/Actions/subscriptionAction";
import { getCouponWithCode } from "../../Redux/Actions/userActions";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./PaymentNew";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHER_KEY_TEST);

const PaymentScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [couponInfo, setCouponInfo] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [cvv, setCvv] = useState("");
  const [myTotal, setMyTotal] = useState("");
  const [cvvError, setCvvError] = useState(false);
  const [coupon, setCoupon] = useState(localStorage.getItem("coupon"));
  const [modalToggle, setModalToggle] = useState(false);
  const [applyCouponChecked, setApplyCouponChecked] = useState(false);
  const {
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs },
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });
  const { userInfo } = useSelector((state) => state.userLogin);
  const { orderDetails, invoice } = useSelector(
    (state) => state.subscriptionDatas
  );
  const [message, setMessage] = useState(null);
  const clientSecret = localStorage.getItem("secret_id");
  const convenianceFee = 10;

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  console.log("------------", modalToggle, "---------------");
  useEffect(() => {
    if (userInfo && userInfo?._id) {
      dispatch(getOrderDetails(userInfo?._id));
    }
  }, [userInfo]);

  const handleSavePayment = (payment_intent) => {
    const userEntry = {
      paymentIntentId: payment_intent,
    };
    dispatch(
      paymentSuccessApi(userEntry, setErrorAlert, setModalToggle, navigate)
    );
  };

  const handleSubmit = () => {
    if (cardNumber === "" || cardNumber.length < 16) {
      setCardNumberError(true);
    }
    if (expiryDate === "" || expiryDate.length < 4) {
      setExpiryDateError(true);
    }
    if (cvv === "" || cvv.length < 3) {
      setCvvError(true);
    }
    if (
      cardNumber !== "" &&
      expiryDate !== "" &&
      cvv !== "" &&
      !erroredInputs.cardNumber &&
      !erroredInputs.expiryDate &&
      !erroredInputs.cvv
    ) {
      const userEntry = {
        user_id: userInfo._id,
        order_id: orderDetails?._id,
        subscribed_by: "SCHOOL",
        coupon_code: coupon,
      };
      console.log(userEntry);
      dispatch(paymentSuccessApi(userEntry, setModalToggle, navigate));
    }
  };

  const getTotalAmount = () => {
    const Amount = orderDetails?.subscriptionInfo?.price + convenianceFee;
    const discount1 = orderDetails?.subscriptionInfo?.price * 0.1;
    const discount2 = orderDetails?.subscriptionInfo?.price * 0.05;
    const total = Amount - (discount1 + discount2);
    if (localStorage.getItem("coupon") && couponInfo) {
      let c_discound =
        (couponInfo / 100) * orderDetails?.subscriptionInfo?.price;
      // return total - orderDetails?.subscriptionInfo?.price * 0.05;
      return total - c_discound;
    }
    return total;
  };

  function expDateValidate(month, year) {
    if (Number(year) > 2035) {
      return "Expiry Date Year cannot be greater than 2035";
    }
    return;
  }

  const removeCoupon = () => {
    localStorage.removeItem("coupon");
    setApplyCouponChecked(false);
    setCoupon("");
    setCouponInfo("");
  };

  const handleApplycoupon = () => {
    localStorage.setItem("coupon", coupon);
    dispatch(
      getCouponWithCode(
        coupon,
        userInfo?._id,
        setApplyCouponChecked,
        applyCouponChecked,
        setCouponInfo
      )
    );
  };

  const handleCardNumberChange = (e) => {
    const inputValue = e.target.value;
    setCardNumberError(false);
    if (inputValue.length <= 19) {
      // Update state or perform other actions
      setCardNumber(inputValue);
    }
  };

  const handleExpiryDateChange = (e) => {
    const inputValue = e.target.value;
    setExpiryDateError(false);
    setExpiryDate(inputValue);
  };

  const modalClose = () => {
    setModalToggle(false);
    localStorage.removeItem("secret_id");
    localStorage.removeItem("coupon_code");
    localStorage.removeItem("coupon_info");
    localStorage.removeItem("coupon");
    if (invoice) {
      navigate(`/invoice/${invoice?._id}`);
    } else {
      navigate(`/home`);
    }
  };

  useEffect(() => {
    if (orderDetails) {
      let _total = getTotalAmount();
      setMyTotal(_total);
    }
  }, [couponInfo, orderDetails]);

  useEffect(() => {
    if (localStorage.getItem("coupon_info")) {
      setCouponInfo(localStorage.getItem("coupon_info"));
    }
  }, []);

  useEffect(() => {
    console.log(localStorage.getItem("coupon"), "157");
    if (localStorage.getItem("coupon")) {
      setCoupon(localStorage.getItem("coupon"));
      setApplyCouponChecked(false);
      // handleApplycoupon();
    }
  }, []);

  return (
    <div>
      <img src={logo} className={classes.paymentlogo} alt="QueensawardLogo" />
      <span className={classes.guestStarIcon}>
        <ColouredStarIcon />
      </span>
      <span className={classes.BaloonhandIcon}>
        <BaloonInHand />
      </span>
      <div className={classes.planDetailsRow}>
        <div md={6} className={classes.planDetailsLeftContainer}>
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                message={message}
                setMessage={setMessage}
                handleSavePayment={handleSavePayment}
              />
            </Elements>
          )}
          {/* <div
            className={`${classes.ContentContainer} ${classes.PAymentContntContainer}`}
          >
            <Typography className={classes.cardPrice}>
              Select Payment Method
            </Typography>
            <Card
              className={`mt-4 ${classes.subscriptionCardMain} ${classes.paymentMethodCard}`}
            >
              <div className={`${classes.MethodsListContainer}`}>
                <div className={classes.cardMethodBox}>
                  <Card
                    className={`${classes.methodCard} ${classes.activeCard}`}
                  >
                    <img
                      src={card}
                      alt="Card Payment"
                      style={{ width: "24px", height: "24px" }}
                    />
                    <Typography className={classes.MethodTxt}>Card</Typography>
                  </Card>
                </div>
                <div className={classes.cardMethodBox}>
                  <Card className={classes.methodCard}>
                    <img
                      src={paypal}
                      alt="Card Payment"
                      style={{ width: "24px", height: "24px" }}
                    />
                    <Typography className={classes.MethodTxt}>
                      Paypal
                    </Typography>
                  </Card>
                </div>
                <div className={classes.cardMethodBox}>
                  <Card className={classes.methodCard}>
                    <img
                      src={applePay}
                      alt="Card Payment"
                      style={{ width: "24px", height: "24px" }}
                    />
                    <Typography className={classes.MethodTxt}>
                      Apple Pay
                    </Typography>
                  </Card>
                </div>
                <div style={{ width: "calc(100% - 81% - 45px)" }}>
                  <Card
                    className={classes.methodCard}
                    style={{ alignItems: "center" }}
                  >
                    <MoreHorizontal />
                  </Card>
                </div>
              </div>
              <div className="mt-3" style={{ position: "relative" }}>
                <Typography className={`mb-1 ${classes.formLable}`}>
                  Card Number
                </Typography>
                <input
                  {...getCardNumberProps({
                    onChange: handleCardNumberChange,
                    value: cardNumber,
                    className: classes.cardNumberInput,
                    placeholder: "XXXX XXXX XXXX XXXX",
                    maxLength: 16,
                  })}
                />
                <svg
                  {...getCardImageProps({
                    images,
                    className: classes.cardImage,
                  })}
                />
                <small className={classes.ErrorText}>
                  {cardNumberError && cardNumber.length !== 16
                    ? "Invalid Card Number"
                    : cardNumberError && cardNumber.length === 0
                    ? "Card Number is Required"
                    : erroredInputs.cardNumber
                    ? erroredInputs.cardNumber
                    : null}
                </small>
              </div>
              <Row className="mt-4">
                <Col xs={6}>
                  <div>
                    <Typography className={`mb-1 ${classes.formLable}`}>
                      Expiry Date
                    </Typography>
                    <input
                      {...getExpiryDateProps({
                        onChange: handleExpiryDateChange,
                        value: expiryDate,
                        className: classes.cardNumberInput,
                        placeholder: "MM/YY",
                      })}
                    />
                    <small className={classes.ErrorText}>
                      {erroredInputs.expiryDate
                        ? erroredInputs.expiryDate
                        : expiryDateError
                        ? "Expiry Date is required"
                        : null}
                    </small>
                  </div>
                </Col>
                <Col xs={6} style={{ position: "relative" }}>
                  <div>
                    <Typography className={`mb-1 ${classes.formLable}`}>
                      CVV
                    </Typography>
                    <input
                      {...getCVCProps({
                        onChange: (e) => {
                          setCvv(e.target.value);
                          setCvvError(false);
                        },
                        value: cvv,
                        className: `${classes.cardNumberInput}`,
                        style: {
                          width: "85%",
                          display: "flex",
                        },
                      })}
                    />
                    <small className={classes.ErrorText}>
                      {(erroredInputs.cvc && erroredInputs.cvc) ||
                        (cvvError && "Cvv is required")}
                    </small>
                  </div>
                  <span className={classes.questionsvg}>
                    <RoundQuestionMark />
                  </span>
                </Col>
              </Row>
              <div className={`mt-4 ${classes.makePaymentBtnContainer}`}>
                <Button
                  className={classes.makePaymentBtn}
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Pay  £{myTotal}.00
                </Button>
              </div>
            </Card>
          </div> */}
        </div>
        <div md={6} className={classes.planDetailsRighttContainer}>
          <div className={classes.textDetailsContainer}>
            <div>
              <Typography className={classes.cardPrice}>
                Payment Information
              </Typography>
              <div className={`mt-3 mb-2 ${classes.paymentDetailsMainDiv}`}>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Payment Method
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    £{orderDetails?.subscriptionInfo?.price}.00
                  </Typography>
                </div>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Discount Amount (5%)
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    - £{orderDetails?.subscriptionInfo?.price * 0.05}.00
                  </Typography>
                </div>
                {localStorage.getItem("coupon") && couponInfo && (
                  <div
                    className={classes.paymentListDiv}
                    style={{ display: applyCouponChecked ? "none" : "flex" }}
                  >
                    <Typography className={classes.paymentSubText}>
                      {localStorage.getItem("coupon")} ({couponInfo}%)
                    </Typography>
                    <Typography
                      className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                    >
                      - £
                      {(couponInfo / 100) *
                        orderDetails?.subscriptionInfo?.price}
                      .00
                    </Typography>
                  </div>
                )}
                {/* {localStorage.getItem("coupon") ? (
                  <div
                    className={classes.paymentListDiv}
                    style={{ display: applyCouponChecked ? "none" : "flex" }}
                  >
                    <Typography className={classes.paymentSubText}>
                      {localStorage.getItem("coupon")} ({couponInfo?.discount}%)
                      <span
                        className={`${classes.applyButton} ${classes.remove}`}
                        onClick={() => removeCoupon()}
                      >
                        Remove
                      </span>
                    </Typography>
                    <Typography
                      className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                    >
                      - £{(couponInfo?.discount /100)*orderDetails?.subscriptionInfo?.price}.00
                    </Typography>
                  </div>
                ) : (
                  <div
                    className={classes.paymentListDiv}
                    style={{ display: applyCouponChecked ? "none" : "flex" }}
                  >
                    <Typography className={classes.paymentSubText}>
                      Coupon Discount
                    </Typography>
                    <Typography
                      className={`${classes.applyButton}`}
                      onClick={() => setApplyCouponChecked(!applyCouponChecked)}
                    >
                      Apply Coupon
                    </Typography>
                  </div>
                )}
                <Grow
                  in={applyCouponChecked}
                  style={{
                    transformOrigin: "0 0 0",
                  }}
                  {...(applyCouponChecked ? { timeout: 1000 } : {})}
                >
                  <div
                    className={classes.Couponcontainer}
                    style={{ display: applyCouponChecked ? "flex" : "none" }}
                  >
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                        width: "310px",
                      }}
                      id="descriptionInput"
                      fullWidth
                      size="small"
                      placeholder="Enter Coupon Code here"
                      className={classes.CouponInput}
                      value={coupon}
                      onChange={(e) => {
                        setCoupon(e.target.value);
                      }}
                    />
                    <div className={`d-flex align-items-center`}>
                      <Typography
                        className={`${classes.applyButton}`}
                        onClick={handleApplycoupon}
                      >
                        Apply
                      </Typography>
                      <Button
                        variant="text"
                        className={classes.closeButton}
                        onClick={() =>{
                          setApplyCouponChecked(!applyCouponChecked);
                          removeCoupon()
                        }}
                      >
                        <CloseBtnIcon />
                      </Button>
                    </div>
                  </div>
                </Grow> */}
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Convenience Fee
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    £{convenianceFee}.00
                  </Typography>
                </div>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    10% Extra off
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    - £{orderDetails?.subscriptionInfo?.price * 0.1}.00
                  </Typography>
                </div>
              </div>
              <Divider />
              <div className={`mt-2`}>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.cardHeader1}>
                    Total Amount (Inc. Tax)
                  </Typography>
                  <Typography className={`${classes.cardHeader1}`}>
                    £{myTotal}.00
                  </Typography>
                </div>
              </div>
            </div>
            {/* <div className={`mt-5 ${classes.makePaymentBtnContainer}`}>
              <Button
                className={classes.makePaymentBtn}
                variant="contained"
                onClick={() => handleSubmit()}
              >
                Make Payment
              </Button>
            </div> */}
          </div>
        </div>
      </div>
      {modalToggle && (
        <PaymentSuccessModel modalClose={modalClose} name={"Subscription"} />
      )}
    </div>
  );
};

export default PaymentScreen;
