import React, { useEffect, useState } from "react";
import Scorm2004API from "scorm-again"; // Import Scorm2004API or equivalent
import _ from "lodash"; // Import lodash or equivalent for merging objects

function GameComponent() {
  const [unloaded, setUnloaded] = useState(false);
  const [dataFromLms, setDataFromLms] = useState({
    // Initial data
    cmi: {
      entry: "ab-initio",
      learner_id: "@jcputney",
      learner_name: "Jonathan Putney",
      scaled_passing_score: 0.5,
    },
  });

  function getSettingsFromParams(urlParams) {
    const settings = {
      autocommit: true,
      autocommitSeconds: 30,
      dataCommitFormat: "json",
      commitRequestDataType: "application/json;charset=UTF-8",
      autoProgress: false,
      logLevel: 1,
      mastery_override: false,
    };

    if (urlParams.get("autocommit") !== null) {
      settings.autocommit = urlParams.get("autocommit") === "true";
    }
    if (urlParams.get("autocommitSeconds") !== null) {
      let seconds = parseInt(urlParams.get("autocommitSeconds"));
      if (isNaN(seconds)) {
        seconds = 60; // default
      }
      settings.autocommitSeconds = seconds;
    }
    if (urlParams.get("dataCommitFormat") !== null) {
      const format = urlParams.get("dataCommitFormat");
      if (format !== null) {
        if (
          format === "json" ||
          format === "params" ||
          format === "flattened"
        ) {
          settings.dataCommitFormat = format;
        }
      }
    }
    if (urlParams.get("logLevel") !== null) {
      let level = parseInt(urlParams.get("logLevel"));
      if (isNaN(level) || level < 1 || level > 5) {
        level = 4; // default
      }
      settings.logLevel = level;
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const settings = getSettingsFromParams(urlParams); // Define getSettingsFromParams function

    // Initialize Scorm2004API
    const API_1484_11 = new Scorm2004API(settings);

    // Event handler for setting values
    const setValueHandler = (CMIElement, value) => {
      API_1484_11.storeData(false);
      // Update UI or perform any necessary actions
    };

    // Event handler for initialization
    const initializeHandler = () => {
      API_1484_11.SetValue("cmi.learner_name", "@jcputney");
    };

    // Load data from LMS
    if (urlParams.get("existing")) {
      //   setDataFromLms(_.merge(dataFromLms, EXISTING_SCORM2004));
    }

    // Attach event listeners
    API_1484_11.on("SetValue.cmi.*", setValueHandler);
    API_1484_11.on("Initialize", initializeHandler);

    // Cleanup function
    return () => {
      if (!unloaded && !API_1484_11.isTerminated()) {
        API_1484_11.SetValue("cmi.exit", "suspend");
        API_1484_11.Commit("");
        API_1484_11.Terminate("");
        setUnloaded(true);
      }
    };
  }, []); // Empty dependency array to run once on mount

  // Event listeners for beforeunload and unload events
  useEffect(() => {
    const unloadHandler = () => {
      if (!unloaded) {
        // Handle unload logic
        return "Are you sure you want to leave?";
      }
    };
    window.addEventListener("beforeunload", unloadHandler);
    window.addEventListener("unload", unloadHandler);

    // Cleanup function
    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
      window.removeEventListener("unload", unloadHandler);
    };
  }, [unloaded]);

  return (
    <div className="container-fluid h-100 m-0 p-0">
      <div className="row no-gutters h-100 align-items-center">
        <iframe
          className="col d-block h-100 border-0 m-0 p-0"
          style={{ backgroundColor: "aqua" }}
          src="https://api.queensaward.education/api/uploads/games/1709962766054c7flp8/index.html"
        ></iframe>
      </div>
    </div>
  );
}

export default GameComponent;
